import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import config from "../config";

const analytics = Analytics({
  app: config.GOOGLE_APP_NAME,
  version: "1",
  // eslint-disable-next-line
  plugins: [
    // eslint-disable-next-line
    googleAnalytics({
      trackingId: config.GOOGLE_TRACKING_ID || "123456",
    }),
  ],
});

export default analytics;
