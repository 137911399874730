import { EventEmitter } from "events";

const AppEvents = new EventEmitter();
const createEvent = <Event extends string, P = null>(type: Event) => ({
  emit: (payload?: P) => {
    setTimeout(() => {
      AppEvents.emit(type, payload);
    });
  },
  on: (cb: (arg?: P) => void) => {
    AppEvents.on(type, (payload: P) => {
      cb(payload);
    });
  },
});

const AppReadyEvent = createEvent("APP_READY");
const UserChangedEvent = createEvent("USER_CHANGED");
const LDAPUserWithNoEmailFoundEvent = createEvent(
  "LDAP_USER_WITH_NO_EMAIL_FOUND"
);

export { AppReadyEvent, UserChangedEvent, LDAPUserWithNoEmailFoundEvent };
