import { FunctionalComponent, h, JSX } from "preact";
import { isAuthenticated, logout } from "../../utils/http";
import Async from "../async";
import Loading from "../loading";

interface Props {
  yes: () => JSX.Element;
  no: () => JSX.Element;
}

const IsAuthenticated: FunctionalComponent<Props> = ({ yes, no }) => (
  <Async promiseFn={isAuthenticated}>
    {({ error, isLoading }) => {
      if (isLoading) {
        return <Loading />;
      }

      if (error && error.code === 401) {
        logout();
        return no();
      }

      return yes();
    }}
  </Async>
);

export default IsAuthenticated;
