import { ChangePasswordInputT, UUID } from "../types";
import { clearCache, get, getCacheFirst, post } from "./api";
import store from "./store";
import { jwtData } from "./helpers";
import config from "../config";
import {
  BuildDetailResponse,
  DownloadResponse,
  ForgotResponse,
  LoginResponse,
  ProjectDetailResponse,
  ProjectsResponse,
  ShareResponse,
  UserResponse,
} from "../types/responses";

type Credentials = {
  username: string;
  password: string;
};

type resetPasswordArgs = {
  token: string;
  uid: string;
  new_password1: string;
  new_password2: string;
};

export const login = ({
  username,
  password,
}: Credentials): Promise<LoginResponse> =>
  post("users/login/", { username, password });

export const isAuthenticated = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const token: string = store.get("token");
    const rToken: string = store.get("refreshToken");

    if (!token || !rToken) {
      reject({ code: 401 });
    }
    if (Date.now() >= jwtData(token).exp * 1000) {
      refreshToken(rToken)
        .then(({ key }) => {
          store.set("token", key);
          resolve();
        })
        .catch(() => reject({ code: 401 }));
    } else {
      resolve();
    }
  });

const refreshToken = (refresh: string): Promise<{ key: string }> =>
  post("users/refresh-token/", { refresh });

export const logout = (): void => {
  store.clear();
  clearCache();
};

export const changePassword = (
  params: ChangePasswordInputT
): Promise<Response> => post("users/password/change/", params);

export const fetchBuild = (id: string | number): Promise<Response> =>
  fetch(config.API_URL + `builds/${id}/download/`, {
    method: "GET",
    headers: {
      "Content-Type": "html/text",
    },
  });

export const forgotPassword = ({
  email,
}: {
  email: string;
}): Promise<ForgotResponse> => post("users/password/reset/", { email });

export const getProfile = (): Promise<UserResponse> =>
  getCacheFirst("users/me/");

export const resetPassword = (args: resetPasswordArgs): Promise<Response> =>
  post("users/password/reset/confirm/", args);

export const getProjects = ({
  offset,
  sortBy,
  search,
}: {
  offset: string;
  sortBy?: string;
  search?: string;
}): Promise<ProjectsResponse> => {
  return get(
    `apps/?sortby=${sortBy}&limit=20&offset=${offset}&search=${search}`
  );
};

export const searchProjects = ({
  search,
}: {
  search: string;
}): Promise<ProjectsResponse> => {
  return get(`apps/?search=${search}`);
};

export const getProject = ({
  uuid,
}: {
  uuid: UUID;
}): Promise<ProjectDetailResponse> => getCacheFirst(`apps/${uuid}/`);

export const getBuild = ({
  uuid,
}: {
  uuid: UUID;
}): Promise<BuildDetailResponse> => getCacheFirst(`builds/${uuid}/`);

export const downloadBuild = ({
  uuid,
}: {
  uuid: UUID;
}): Promise<DownloadResponse> => get(`builds/${uuid}/request/`);

export const getBuildShares = ({
  uuid,
}: {
  uuid: UUID;
}): Promise<ShareResponse> => get(`builds/${uuid}/shares/`);

export const saveShare = (id: UUID, type = "build", data = {}): Promise<void> =>
  post(`${type}s/${id}/shares/`, data);
