import cn from "classnames";
import { FunctionalComponent, h } from "preact";
import { useState } from "preact/hooks";
import Icon from "../icon";
import { EventTargetType } from "../../types";
import Text, { TextColors } from "../text";

import "./index.scss";

interface Props {
  icon?: string[];
  error?: string | null;
  onChange?: (args?: EventTargetType) => void;
  onInput?: (args?: EventTargetType | KeyboardEvent) => void;
  iconColor?: TextColors;
  onBlur?: (args?: Event) => void;
  placeholder?: string;
  className?: string;
  name: string;
  type: string;
  id?: string;
  label?: string;
  required?: boolean;
  position?: string;
  value?: string;
}

interface State {
  type: string;
  icon: string;
}

const Input: FunctionalComponent<Props> = ({
  icon,
  className,
  error,
  iconColor,
  type,
  required,
  name,
  position,
  label,
  value,
  id,
  ...props
}) => {
  const [state, setState] = useState<State>({
    type,
    icon: icon ? icon[0] : undefined,
  });

  const revealPassword = (): void => {
    if (name.toLowerCase().includes("password")) {
      const newType = state.type === "text" ? "password" : "text";
      const newIcon = state.icon === icon[0] ? icon[1] : icon[0];
      setState({
        ...state,
        type: newType,
        icon: newIcon,
      });
    }
  };

  return (
    <div className={cn("Input", { "Input--focused": value })}>
      <label htmlFor={id || name}>
        {icon && (
          <Icon
            size={4}
            color={iconColor}
            name={state.icon}
            onClick={revealPassword}
            className="Input__icon"
          />
        )}
        {error && (
          <div className="mb-2 Input__error">
            <Text content={error} />
          </div>
        )}
        {/* @ts-ignore */}
        <input
          name={name}
          required={required}
          className={cn(
            "Input__field",
            {
              "Input--top": position === "top",
              "Input--bottom": position === "bottom",
              "Input--middle": position === "middle",
              "Input--bottom Input--top": position === "top-bottom",
            },
            className
          )}
          type={state.type}
          id={id || name}
          value={value || ""}
          {...props}
        />
        {label && (
          <label className="Input__label" htmlFor={id || name}>
            <Text content={label} />
          </label>
        )}
      </label>
    </div>
  );
};

export default Input;
