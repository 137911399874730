import { h } from "preact";
import { route } from "preact-router";
import Button from "../../components/button";
import { withStore } from "../../store";
import { useTheme } from "../../utils/hooks";

export const InternalServerErrorPage = withStore(() => {
  const [theme] = useTheme();

  return (
    <div
      className={
        "first-layer h-full flex flex-col align-center justify-between"
      }
    >
      <div />
      <div className={"text-center"}>
        <img
          className={"mb-5 w-2/3 h-auto text-center"}
          src="../../assets/server-error.png"
          alt="Page not found."
        />
        <div>
          <div className={"text-normal font-black text-center"}>
            Internal server error
          </div>
          <div className={"text-small text-center"}>
            We have an internal server error. Try again later.
          </div>
        </div>
      </div>
      <div className={"px-2 my-7 w-full"}>
        <Button
          text={"Back to Home"}
          gradient
          backgroundColor={theme === "dark" && theme}
          bottom_float
          onClick={() =>
            setTimeout(() => {
              route("/home");
            })
          }
        />
      </div>
    </div>
  );
});
