import { ProjectSortOrder, ProjectType } from "../types";

type FilterEntry = { key: ProjectType };
type SortByEntry = { key: ProjectSortOrder };

type FilterEntryType = "platforms" | "sorting";

export const FILTER_ITEMS: Readonly<FilterEntry[]> = [
  { key: "ios" },
  { key: "android" },
  { key: "universal" },
];
export const SORTBY_ITEMS: Readonly<SortByEntry[]> = [
  { key: "default" },
  { key: "name" },
];

export type Filter = Readonly<{ key: string }[]>;

export type FilterItem = {
  title: string;
  type: FilterEntryType;
  items: Filter;
  onChange: (string) => void;
  selected: string[];
};

export const errorCodes = {
  userNotFound: "user_not_found",
  tooManyAttempts: "too_many_attempts",
  invalidToken: "token_not_valid",
  credentialsNotProvided: "Authentication credentials were not provided.",
};
