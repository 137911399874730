import { FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import { useCallback, useEffect, useState } from "preact/hooks";
import Loading from "../../components/loading";
import ProjectItem from "../../components/projectitem";
import analytics from "../../utils/analytics";
import { ProjectList, ThemeType } from "../../types";
import Icon from "../icon";
import MessageView from "../messageView";

import "./index.scss";

const MAX_SIZE = 100;

type Props = {
  theme: ThemeType;
  content: ProjectList;
  refreshFn: (sortBy?: string) => void;
  lockScroll: boolean;
};

type State = {
  startY: number;
  currentY: number;
};

const PullToRefresh: FunctionalComponent<Props> = ({
  lockScroll,
  theme,
  content,
  refreshFn,
}) => {
  const [state, setState] = useState<State>({
    startY: 0,
    currentY: 0,
  });

  const touchStart = useCallback(
    (e: TouchEvent) => {
      if (!lockScroll) {
        setState({
          ...state,
          startY: e.touches[0].pageY,
        });
      }
    },
    [state, lockScroll]
  );

  const handleProjectItemClick = (uuid: string) => {
    // eslint-disable-next-line
    analytics
      .track("projectClicked", {
        value: uuid,
      })
      .then(() => route(`/project/${uuid}`));
  };

  const touchMove = useCallback(
    (e: TouchEvent) => {
      const y = e.touches[0].pageY;
      if (!lockScroll) {
        if (document.scrollingElement.scrollTop === 0) {
          const currentY = Math.min(MAX_SIZE, y - state.startY);
          setState({
            ...state,
            currentY: currentY < 0 ? 0 : currentY,
          });
        }
      }
    },
    [lockScroll, state]
  );

  // TODO this is not working correctly in some ios devices, test device T-141
  const touchEnd = useCallback(() => {
    const { currentY } = state;
    if (!lockScroll) {
      if (
        currentY > 0 &&
        document.scrollingElement.scrollTop <= 0 &&
        currentY > MAX_SIZE * 0.4 // bigger than 2/5
      ) {
        refreshFn();
      }

      setState({
        ...state,
        currentY: 0,
      });
    }
  }, [state, lockScroll, refreshFn]);

  useEffect(() => {
    const el = document;
    el.addEventListener("touchstart", touchStart, { passive: true });
    el.addEventListener("touchmove", touchMove, { passive: true });
    el.addEventListener("touchend", touchEnd, { passive: true });
    el.addEventListener("touchcancel", touchEnd, { passive: true });

    return () => {
      el.removeEventListener("touchstart", touchStart);
      el.removeEventListener("touchmove", touchMove);
      el.removeEventListener("touchend", touchEnd);
      el.removeEventListener("touchcancel", touchEnd);
    };
  }, [state, lockScroll, touchEnd, touchMove, touchStart]);

  const scale = ((MAX_SIZE / 100) * state.currentY) / 100;
  return (
    <div>
      <div
        class="refresher"
        style={{
          transform: `scale(${scale})`,
          opacity: scale * 2,
        }}
      >
        <Icon name="arrow-down-download" />
      </div>
      <div
        class="refresh-content"
        style={{
          transform: `translateY(${state.currentY}px)`,
        }}
      >
        {content.map((item, index) => {
          if (item.componentType === "NoProjects") {
            return (
              <MessageView
                key={`${item.componentType}-${index}`}
                icon="noProjectsIllustration"
                title="warning.no_project.title"
                content="warning.no_project.content"
              />
            );
          } else if (item.componentType === "Loading") {
            return <Loading key={`${item.componentType}-${index}`} />;
          } else {
            return (
              <ProjectItem
                {...item.data}
                uuid={item.data.uuid}
                theme={theme}
                key={item.data.uuid}
                handleClick={() => handleProjectItemClick(item.data.uuid)}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default PullToRefresh;
