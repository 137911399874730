import cn from "classnames";
import { FunctionalComponent, h, JSX, Fragment } from "preact";
import "./index.scss";

type Props = {
  children?: JSX.Element | JSX.Element[];
  square?: boolean;
  element?: JSX.Element | string;
  elevation?: number;
  className?: string;
  hover?: boolean;
  onClick?: () => void;
  key?: string | number;
};

const Paper: FunctionalComponent<Props> = ({
  children,
  element = "div",
  elevation = 2,
  square = false,
  className,
  hover = false,
  ...rest
}) => {
  const Tag = element;
  const classes = cn(
    "third-layer",
    "Paper",
    `Paper--elevation-${elevation}`,
    {
      "Paper--square": square,
      "Paper--hover": hover,
    },
    className
  );
  return (
    <Fragment>
      {/* @ts-ignore */}
      <Tag className={classes} {...rest}>
        {children}
      </Tag>
    </Fragment>
  );
};

export default Paper;
