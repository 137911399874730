import { Fragment, FunctionalComponent, h } from "preact";
import TopBar from "../../components/topNav";
import { useState } from "preact/hooks";
import Button from "../../components/button";
import { useTheme } from "../../utils/hooks";
import { isEmail } from "../../components/form/validators";
import ProfileInfoScreen from "./ProfileInfoScreen";
import Form from "../../components/form";
import GroupsScreen from "./GroupsScreen";

import "./index.scss";

const inputs = [
  {
    name: "username",
    type: "text",
    label: "placeholder.user_name",
    position: "top",
    required: true,
    validation: [],
  },
  {
    name: "email",
    type: "text",
    label: "placeholder.email",
    position: "middle",
    required: true,
    validation: [],
  },
  {
    name: "name",
    type: "text",
    label: "placeholder.name",
    position: "bottom",
    required: true,
    validation: [],
  },
];

interface State {
  screen: number;
  loading: boolean;
  isSubmitDisabled: boolean;
  emailError: string;
  notification: string;
  inputs: {
    username: string;
    email: string;
    name: string;
    search: string;
  };
}

const AddUserScreen: FunctionalComponent = () => {
  const [state, setState] = useState<State>({
    screen: 1,
    loading: false,
    isSubmitDisabled: true,
    emailError: null,
    notification: null,
    inputs: {
      username: "",
      email: "",
      name: "",
      search: "",
    },
  });

  const [theme] = useTheme();

  const { loading, notification, emailError, screen, isSubmitDisabled } = state;
  const { email, name, username, search } = state.inputs;

  const rules = {};
  const btnText = screen === 1 ? "add_user.next_step" : "add_user.create_user";
  const handleChangeInput = (e: KeyboardEvent) => {
    const target = e.target as HTMLTextAreaElement;
    const emailError =
      target.name === "email" ? !isEmail(target.value) : state.emailError;
    const submitDisabled =
      loading || username === "" || name === "" || email === "";

    setState({
      ...state,
      emailError: emailError && "error.form.email",
      isSubmitDisabled: submitDisabled,
      inputs: {
        ...state.inputs,
        [target.name]: target.value,
      },
    });
  };

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    if (emailError || loading || notification) {
      return;
    }
    if (screen === 1) {
      setState({
        ...state,
        screen: 2,
      });

      return;
    }
  };

  return (
    <Form className="addUser" rules={rules} onSubmit={handleSubmit}>
      {() => (
        <Fragment>
          <TopBar />
          {screen === 1 && (
            <ProfileInfoScreen
              inputs={inputs}
              notification={notification}
              handleChangeInput={handleChangeInput}
              emailError={emailError}
              stateInputs={state.inputs}
            />
          )}
          {screen === 2 && <GroupsScreen search={search} results={[]} />}
          <div className="button-container">
            <Button
              text={btnText}
              gradient
              backgroundColor={theme === "dark" && theme}
              bottom_float
              disabled={isSubmitDisabled}
            />
          </div>
        </Fragment>
      )}
    </Form>
  );
};

export default AddUserScreen;
