export default {
  API_URL: process.env.PREACT_APP_API_URL,
  API_VERSION: process.env.PREACT_APP_API_VERSION,
  GOOGLE_APP_NAME: process.env.PREACT_APP_GOOGLE_APP_NAME,
  GOOGLE_TRACKING_ID: process.env.PREACT_APP_GOOGLE_TRACKING_ID,
} as {
  API_URL: string;
  API_VERSION: string;
  GOOGLE_APP_NAME: string;
  GOOGLE_TRACKING_ID: string;
};
