import { FunctionalComponent, h } from "preact";
import Button from "../../button";
import Text from "../../text";
import Modal, { ModalProps } from "../index";
import "./index.scss";
import { ThemeType } from "../../../types";

interface Props extends ModalProps {
  title: string;
  buttonText?: string;
  onConfirm: (event?: Event) => void;
  className?: string;
  theme: ThemeType;
}

const Dialog: FunctionalComponent<Props> = ({
  title,
  theme,
  className,
  buttonText,
  onConfirm,
  onClose,
  children,
}) => {
  return (
    <Modal onClose={onClose}>
      <div className={(className ? className : "") + " modal"}>
        <Text content={title} bold="bold" size="large" />
        {children}
        <Button
          text={buttonText ? buttonText : "OK"}
          className="mt-3"
          gradient
          backgroundColor={theme === "dark" && theme}
          onClick={onConfirm}
        />
      </div>
    </Modal>
  );
};
export default Dialog;
