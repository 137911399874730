import { FunctionalComponent, h, JSX } from "preact";
import "./index.scss";

export const DEFAULT_HEADER_BG_COLOR = "#ffffff";

interface Props {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  children?: JSX.Element | string;
  backgroundColor?: string;
  unfixed?: boolean;
}

const Header: FunctionalComponent<Props> = ({
  leftIcon,
  rightIcon,
  backgroundColor,
  children,
  unfixed,
}) => (
  <header
    className={`second-layer header
          ${unfixed ? "header--unfixed" : ""}`}
    style={{
      backgroundColor,
    }}
  >
    <div className="header__icon header__icon--left">{leftIcon}</div>
    <div className="header__container">{children}</div>
    <div className="header__icon header__icon--right">{rightIcon}</div>
  </header>
);

export default Header;
