import { FunctionalComponent, h } from "preact";
import { useState } from "preact/hooks";
import Button from "../../components/button"; // IconColorButton
import Text from "../../components/text";
import { ProjectItem as ProjectItemType, ThemeType } from "../../types";
import {
  getMobileOS,
  getUuifFromUrl,
  iOSSafari,
  isMobile,
  isRunningInStandaloneMode,
} from "../../utils/helpers";
import analytics from "../../utils/analytics";
import { fetchBuild } from "../../utils/http";
import {
  copyTextToClipboard,
  getContrastColor,
  projectURL,
} from "../../utils/text";
import { formatBuildDateTime } from "../../utils/time";
import Icon from "../icon";
import Paper from "../paper";

import "./index.scss";
import { broadcast } from "../toast";
import { withText } from "preact-i18n";

interface Props extends ProjectItemType {
  theme: ThemeType;
  handleClick: () => void;
  key?: string | number;
  copiedToClipboardMessage?: string;
  warningNotAvailableTitle?: string;
  warningNotAvailableContent?: string;
  warningIncompatibleTitle?: string;
  warningIncompatibleContent?: string;
}

interface State {
  loading: boolean;
}

const ProjectItemComponent: FunctionalComponent<Props> = ({
  uuid,
  theme,
  type,
  latest_build,
  icon,
  name,
  primary_color,
  handleClick,
  copiedToClipboardMessage,
  warningNotAvailableTitle,
  warningNotAvailableContent,
  warningIncompatibleTitle,
  warningIncompatibleContent,
}) => {
  const [state, setState] = useState<State>({
    loading: false,
  });

  const isAndroidDevice = getMobileOS() === "android";
  const isIosDevice = getMobileOS() === "ios";

  const handleBuildDownload = (
    e: Event,
    url: string,
    incompatibleForce = false
  ) => {
    e.stopPropagation();
    if (
      (isMobile() &&
        type === "android" &&
        !isAndroidDevice &&
        !incompatibleForce) ||
      (isMobile() && type === "ios" && !isIosDevice && !incompatibleForce)
    ) {
      broadcast(warningIncompatibleTitle, warningIncompatibleContent);
    }

    setState({
      ...state,
      loading: true,
    });

    if (iOSSafari() && !isRunningInStandaloneMode()) {
      // eslint-disable-next-line
      analytics.track("ApkDownload").then(() => {
        window.location.href = url;
        setState({
          ...state,
          loading: false,
        });
      });
      return;
    }

    const buildUuid = getUuifFromUrl(url);
    setState({
      ...state,
      loading: true,
    });

    // eslint-disable-next-line
    fetchBuild(buildUuid).then((res) => {
      if (res.status !== 200) {
        broadcast(warningNotAvailableTitle, warningNotAvailableContent);
      } else {
        // eslint-disable-next-line
        analytics.track("ApkDownload").then(() => {
          window.location.href = url;
          setState({
            ...state,
            loading: false,
          });
        });
      }
    });
  };

  // TODO maybe we have to remove that but for now is seems to be a good fallback
  const defaultIcon = "../assets/icons/android-chrome-72x72.png";
  const defaultColor = "#64e5ce";
  primary_color =
    primary_color === "#000000" ? "#ffffff" : primary_color || defaultColor;
  icon = icon || defaultIcon;

  const textColor = getContrastColor(primary_color, 125);
  const buttonColor =
    theme === "dark" ? theme : getContrastColor(primary_color, 225);
  const backgroundColor = theme === "dark" ? "#222222" : primary_color;

  const isAndroidApk = type === "android";

  return (
    <div
      className="project-item"
      style={{ backgroundColor }}
      onClick={handleClick}
    >
      <div className="project-item__body">
        <div className="project-item__main">
          <div className="project-item__icon mb-5 mt-3">
            <img alt="App icon" src={icon} />
          </div>

          <div className="project-item__text mb-2">
            <Text
              color={textColor}
              opacity="medium"
              bold="bold"
              size="normal"
              className="mb-2"
            >
              {name}
            </Text>
            {latest_build && (
              <span className="flex justify-center">
                <Text color={textColor} bold="black" size="large">
                  <Icon
                    className={`${!isAndroidApk && "apple-icon"} mr-2`}
                    name={isAndroidApk ? "android" : "apple"}
                    size={3}
                  />
                  {latest_build?.version} (#
                  {latest_build?.build_number})
                </Text>
              </span>
            )}
          </div>
        </div>
        {latest_build && (
          <div class="project-item__more-info mb-3 mt-3">
            <Text color={textColor} size="small" opacity="medium">
              {formatBuildDateTime(latest_build.created, "long")} &nbsp;
            </Text>
          </div>
        )}
        {latest_build &&
          (latest_build.binary_exists ? (
            <div className="project-item__download mb-4 mt-4 display-inline-flex flex-row align-center justify-center">
              <Button
                backgroundColor={buttonColor}
                className="mr-2 "
                size={"xl3"}
                iconOnly={true}
                iconLarge={true}
                color={primary_color}
                text={""}
                icon={theme === "dark" ? "link-small-white" : "link-small"}
                loading={state.loading}
                disabled={state.loading}
                noBorder={true}
                bold={"bold"}
                onClick={(event: Event) => {
                  const urlToCopy = projectURL(
                    "build",
                    uuid,
                    latest_build?.build_number
                  );
                  copyTextToClipboard(urlToCopy);
                  broadcast(copiedToClipboardMessage, "");
                  event.stopPropagation();
                }}
              />

              <Button
                backgroundColor={buttonColor}
                size={"sm"}
                color={primary_color}
                text={"button.download"}
                loading={state.loading}
                disabled={state.loading}
                noBorder={true}
                bold={"bold"}
                onClick={(event: Event) => {
                  handleBuildDownload(event, latest_build.download_url);
                }}
              />
            </div>
          ) : (
            <Paper elevation={2}>
              <div className="Paper__container m-4">
                <Icon name={"warning-small"} size={4} className="ml-3 mr-3" />
                <Text
                  size="xsmall"
                  bold="semibold"
                  content="warning.unavailable_build"
                />
              </div>
            </Paper>
          ))}
      </div>
    </div>
  );
};

// eslint-disable-next-line
const ProjectItem = withText({
  copiedToClipboardMessage: "messages.copiedtoclipboard",
  warningNotAvailableTitle: "warning.modal.title",
  warningNotAvailableContent: "warning.modal.content",
  warningIncompatibleTitle: "warning.incompatible.title",
  warningIncompatibleContent: "warning.modal.content",
})(ProjectItemComponent);

export default ProjectItem;
