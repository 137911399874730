import { ComponentType, VNode, createContext, h } from "preact";
import store from "../utils/store";
import { ThemeType } from "../types";

export interface StoreState {
  showLDAPUserWithNoEmailWarning: boolean;
  theme: ThemeType;
}

export interface StoreContextValue {
  state: StoreState;
  update: (newSate: Partial<StoreState>) => StoreState;
}

export interface StoreProp {
  store: StoreContextValue;
}

const initialTheme: ThemeType = store.getTheme("theme") || "light";

if (typeof window !== "undefined") {
  document.body.style.background = initialTheme === "dark" && "#000000";
}

const initialState: StoreState = {
  showLDAPUserWithNoEmailWarning: false,
  theme: initialTheme,
};

export const getInitialStoreState = (
  updateStore: (update: Partial<StoreState>) => StoreState
): StoreContextValue => ({
  state: initialState,
  update: updateStore,
});

export const Store = createContext(null);

export function withStore<P>(TargetComponent: ComponentType<P & StoreProp>) {
  return function WithStore(props: P): VNode {
    return (
      <Store.Consumer>
        {(currentStore: StoreContextValue) => {
          return <TargetComponent {...props} store={{ ...currentStore }} />;
        }}
      </Store.Consumer>
    );
  };
}
