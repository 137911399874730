import { FunctionalComponent, h } from "preact";
import Icon from "../icon";

interface Props {
  checked?: boolean;
}

const Checkbox: FunctionalComponent<Props> = ({ checked = false }) => (
  <Icon
    name={checked ? "checkmark" : "checkmarkDisabled"}
    className="Button__icon"
    size={4}
  />
);

export default Checkbox;
