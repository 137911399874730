import { Component, ComponentClass, ComponentType, h } from "preact";
import { route } from "preact-router";

import IsAuthenticated from "../components/isauthenticated";
import store from "../utils/store";

// eslint-disable-next-line
export default (ComposedComponent: ComponentType | any): ComponentClass => {
  class Authentication<P> extends Component<P> {
    public render() {
      return (
        <IsAuthenticated
          yes={() => {
            return <ComposedComponent {...this.props} />;
          }}
          no={() => {
            const redirect = window.location.pathname + window.location.search;

            store.set("redirect", redirect);

            route("/login");

            return null;
          }}
        />
      );
    }
  }

  return Authentication;
};
