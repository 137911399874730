import { FunctionalComponent, h, JSX } from "preact";
import { useEffect, useLayoutEffect, useState } from "preact/hooks";
import { toggleScrolling } from "../../utils/helpers";
import "./index.scss";
export interface ModalProps {
  children?: JSX.Element;
  onClose?: () => void;
}

const Modal: FunctionalComponent<ModalProps> = ({ children, onClose }) => {
  const [size, setSize] = useState([0, 0]);
  const maxHeight = 700; // max height to not switch to full view

  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight]);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    toggleScrolling(true);
    return (): void => toggleScrolling(false);
  }, []);

  return (
    <div
      className={size[1] < maxHeight ? `modal-wrapper-full` : `modal-wrapper`}
    >
      <div class="grayout" onClick={onClose} />
      {children}
    </div>
  );
};

export default Modal;
