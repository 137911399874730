import { FunctionalComponent, h } from "preact";
import {
  ProjectFilter,
  ProjectSorting,
  ProjectSortOrder,
  ProjectType,
  ThemeType,
} from "../../types";

import "./index.scss";

const sortingOptions: ProjectSorting = [
  {
    type: "default",
    text: "Date",
  },
  {
    type: "name",
    text: "Name",
  },
];

interface DesktopSortingMenuType {
  theme: ThemeType;
  onChange: (
    arg1: ProjectFilter | ProjectType[],
    arg2: ProjectSortOrder[]
  ) => void;
  currentSorting: ProjectSortOrder[];
  currentFiltering: ProjectFilter | ProjectType[];
}

const DesktopSortingMenu: FunctionalComponent<DesktopSortingMenuType> = ({
  theme,
  onChange,
  currentSorting,
  currentFiltering,
}) => {
  return (
    <div className="second-layer desktop-sorting-menu">
      {sortingOptions.map((option) => {
        const isSelected: boolean = currentSorting[0] === option.type;

        return (
          <div
            key={option.type}
            className={`desktop-sorting-menu-option
                ${isSelected ? "desktop-sorting-menu-option--selected" : ""}
              `}
            onClick={() => onChange(currentFiltering, [option.type])}
          >
            <span
              style={{
                color: theme === "dark" && "#ffffff",
              }}
            >
              {option.text}
            </span>
          </div>
        );
      })}
      <hr />
    </div>
  );
};

export default DesktopSortingMenu;
