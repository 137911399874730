import cn from "classnames";
import { FunctionalComponent, h, JSX } from "preact";

import "./index.scss";

type Props = {
  children?: JSX.Element | JSX.Element[];
  vcenter?: boolean;
  hcenter?: boolean;
  padded?: boolean;
  noHeader?: boolean;
  fullHeight?: boolean;
  fullHeightPlus?: boolean;
  firstLayer?: boolean;
};

const Content: FunctionalComponent<Props> = ({
  children,
  vcenter = false,
  hcenter = false,
  padded = false,
  noHeader = false,
  fullHeight = false,
  fullHeightPlus = false,
  firstLayer = false,
}) => {
  return (
    <main
      className={cn("content", {
        "content--vcenter": vcenter,
        "content--hcenter": hcenter,
        "content--padded": padded,
        "content--noHeader": noHeader,
        "content--h-full": fullHeightPlus,
        "first-layer": firstLayer,
        "h-full": fullHeight,
      })}
    >
      {children}
    </main>
  );
};

export default Content;
