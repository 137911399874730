import cn from "classnames";
import { FunctionalComponent, h } from "preact";
import { useTheme } from "../../utils/hooks";
import Icon from "../icon";

import "./index.scss";

interface Props {
  onChange: (event: Event) => void;
  onSubmit: (params?: unknown) => void;
  onReset: (event: Event) => void;
  placeholder?: string;
  name: string;
  value?: string;
  disabled?: boolean;
}

const SearchInput: FunctionalComponent<Props> = ({
  name,
  value,
  placeholder,
  disabled,
  onChange,
  onSubmit,
  onReset,
}) => {
  const [theme] = useTheme();
  const handleSubmit = (event: Event) => {
    event.preventDefault();
    onSubmit();
  };

  const handleReset = (event: Event) => {
    event.preventDefault();
    onReset(event);
  };

  const isDarkTheme = theme === "dark";

  return (
    <form
      className={cn("Search_input", {
        "Search_input--dark": isDarkTheme,
      })}
      onSubmit={handleSubmit}
    >
      <Icon
        onClick={handleSubmit}
        className="Search_input-icon"
        name="search"
        color={isDarkTheme ? "light" : "dark"}
        size={4.5}
      />

      <input
        name={name}
        placeholder={disabled ? "Loading..." : placeholder}
        value={value}
        onInput={onChange}
        disabled={disabled}
      />

      {value && (
        <Icon
          onClick={handleReset}
          className="Search_input-reset"
          name="close"
          color={isDarkTheme ? "light" : "dark"}
          size={4.5}
        />
      )}
    </form>
  );
};

export default SearchInput;
