import { FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import { withStore } from "../../store";
import Button from "../../components/button";
import Header from "../../components/header";

export const OfflineErrorPage: FunctionalComponent = withStore(({ store }) => {
  const { theme } = store.state;

  return (
    <div
      className={"first-layerflex h-full flex-col align-center justify-between"}
    >
      <div>
        <Header>Offline</Header>
      </div>
      <div className={"text-center"}>
        <div className={"text-normal font-black text-center"}>
          Can&apos;t connect to internet.
        </div>
      </div>
      <div className={"px-2 my-7 w-full flex justify-center"}>
        <Button
          text={"Back to Home"}
          gradient
          backgroundColor={theme === "dark" && theme}
          bottom_float
          onClick={() =>
            setTimeout(() => {
              route("/home");
            })
          }
        />
      </div>
    </div>
  );
});
