import cn from "classnames";
import { FunctionalComponent, h } from "preact";
import Checkbox from "../../../../components/checkbox";
import Text from "../../../../components/text";
import { ShareItem } from "../../../../types";
import "./index.scss";

interface Props {
  selected: string[];
  items: ShareItem[];
  onSelect: (item?: ShareItem) => void;
}

const ShareSelected: FunctionalComponent<Props> = ({
  selected = [],
  items = [],
  onSelect = () => null,
}) => {
  const fallbackAvatar =
    "https://i.pinimg.com/originals/52/fe/c5/52fec54732ea8fa383f614f447aec4ac.jpg";

  return (
    <div className="ShareSelected">
      {items && items.length > 0 && (
        <div className="ShareSelected__list">
          {items.map((item, index) => {
            const avatar =
              item.avatar || (item.users_info[0] && item.users_info[0].avatar);
            return (
              <div
                key={item.uuid}
                onClick={() => onSelect(item)}
                className={cn("ShareSelected__item", {
                  "ShareSelected--last": index + 1 === items.length,
                })}
              >
                <div className="flex align-center">
                  {avatar ? (
                    <div className="ShareSelected__avatar mb-2 mt-2">
                      <img alt="Deployplus user icon" src={avatar} />
                    </div>
                  ) : (
                    <div className="ShareSelected__avatar mb-2 mt-2">
                      <img alt="Deployplus user icon" src={fallbackAvatar} />
                    </div>
                  )}
                  <Text
                    content={item.name}
                    className="ShareSelected__name ml-4"
                    size="normal"
                    color="text"
                    spacing="-0.8px"
                    bold="thin"
                  />
                </div>
                <div className="ShareSelected__check">
                  <Checkbox checked={selected.includes(item.uuid)} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ShareSelected;
