import { FunctionalComponent, h } from "preact";
import Text from "../../components/text";

interface Props {
  search: string;
  results: [];
}

const GroupsScreen: FunctionalComponent<Props> = ({ search, results }) => {
  return (
    <div
      style={{
        padding: "0 var(--project-item-gutter)",
      }}
    >
      <Text
        content="add_user.assign_groups"
        left
        bold="bold"
        color="light-gray"
        size="slarge"
        className="mt-5 mb-4"
      />
      <div className="second-layer wide-button">
        <p>
          {search}
          {results}
        </p>
      </div>
    </div>
  );
};

export default GroupsScreen;
