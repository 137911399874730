import { FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import Icon from "../icon";

import "./index.scss";

interface Props {
  icon: string;
  label: string;
  path: string;
  selected?: boolean;
  onClick?: () => void;
}

const TopNavButton: FunctionalComponent<Props> = ({
  icon,
  label,
  path,
  selected,
  onClick,
}) => {
  return (
    <div
      className={`topNav__button ${selected ? "topNav__button--selected" : ""}`}
      onClick={() => (onClick ? onClick() : route(path))}
    >
      <Icon name={icon} size={4} />
      <span className="topNav__button__label">{label}</span>
    </div>
  );
};

export default TopNavButton;
