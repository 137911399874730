import { h } from "preact";
import { route } from "preact-router";
import { useTheme } from "../../utils/hooks";
import { useEffect, useState } from "preact/hooks";

import Async, { AsyncCallbackParams } from "../../components/async";
import Content from "../../components/content";
import Head from "../../components/head";
import TopBar from "../../components/topNav";
import Loading from "../../components/loading";
import Dialog from "../../components/modal/dialog";
import ModalMessage from "../../components/modal/message";
import Text from "../../components/text";
import { AuthType, ThemeType } from "../../types";
import { UserResponse } from "../../types/responses";
import { withStore } from "../../store";
import { getProfile, logout } from "../../utils/http";
import Icon from "../../components/icon";
import analytics from "../../utils/analytics";

import "./index.scss";
import ToggleSwitch from "../../components/toggleSwitch";

type ResponsesUsers = AsyncCallbackParams<UserResponse>;
type State = {
  showDropDown: boolean;
  modalOpen: boolean;
  analyticsSet: boolean;
};

export default withStore(({ store }) => {
  const [state, setState] = useState<State>({
    showDropDown: false,
    modalOpen: false,
    analyticsSet: false,
  });
  const [theme, toggleTheme] = useTheme();

  const handleThemeChange = (): void => {
    const oppositeTheme = theme === "dark" ? "light" : "dark";
    // eslint-disable-next-line
    analytics.track("themeChanged", {
      theme: oppositeTheme,
    });

    store.update({ theme: oppositeTheme });
    toggleTheme();
  };

  const handleLogout = (): void => {
    logout();
    route("/login");
  };

  const handleModalButton = (): void => {
    setState({
      ...state,
      modalOpen: false,
    });
  };

  const changePassword = (authType: AuthType) => {
    if (authType === "LOCAL") {
      route("/change-password");
    } else {
      setState({
        ...state,
        modalOpen: true,
        showDropDown: false,
      });
    }
  };

  useEffect(() => {
    if (!state.analyticsSet) {
      // eslint-disable-next-line
      analytics.page();
      setState({
        ...state,
        analyticsSet: true,
      });
    }
  }, [state]);

  return (
    <div className="first-layer profile">
      {state.modalOpen && (
        <Dialog
          theme={theme}
          title="modal.title"
          onConfirm={handleModalButton}
          onClose={handleModalButton}
        >
          <ModalMessage content="modal.content" />
        </Dialog>
      )}

      <Head title="Profile" />
      <TopBar />
      <div
        style={{
          margin: "0 auto",
          maxWidth: 600,
          padding: "0 var(--project-item-gutter)",
        }}
      >
        <Content vcenter={true}>
          <Async promiseFn={getProfile}>
            {({ data, error, isLoading }: ResponsesUsers) => {
              if (isLoading) {
                return <Loading />;
              }

              if (error) {
                return <p>{error.toString()}</p>;
              }

              if (data) {
                return (
                  <div>
                    <ProfileView {...data} />
                    <ThemeView
                      theme={theme}
                      handleThemeChange={handleThemeChange}
                    />
                    <div
                      className="second-layer wide-button"
                      onClick={() => changePassword(data.auth_type)}
                    >
                      <Icon className="mr-1" name="lock" size={3} />
                      <Text
                        left
                        bold="bold"
                        color={"dark"}
                        opacity="high"
                        content="profile.change_password"
                      />
                    </div>
                    <p
                      style={{
                        marginTop: 50,
                        textAlign: "left",
                        fontSize: 14,
                        fontWeight: 600,
                        color: "#FF0000",
                      }}
                      onClick={handleLogout}
                    >
                      Logout
                    </p>
                  </div>
                );
              }
              return null;
            }}
          </Async>
        </Content>
      </div>
    </div>
  );
});

const ProfileView = ({ username, email, first_name, last_name, role }) => (
  <div className="profile-form">
    <div className="profile-form__personal-info">
      <div className="profile-form__personal-info_text">
        <Text bold="bold" left size="large" className="mt-2">
          {first_name} {last_name}
        </Text>
        <Text left size="normal" color="light-gray">
          {role}
        </Text>
      </div>
    </div>
    <Text
      content="profile.title.information"
      left
      bold="bold"
      color="light-gray"
      size="slarge"
      className="mt-6 mb-4"
    />
    <div className="second-layer profile-form__contact-info profile-form--top">
      <Text
        content="placeholder.user_name"
        bold="bold"
        left
        size="xsmall"
        className="mb-2"
      />
      <Text left size="normal" className="mt-1">
        {username}
      </Text>
    </div>
    <div className="second-layer profile-form__contact-info profile-form--bottom">
      <Text
        content="placeholder.email"
        bold="bold"
        left
        size="xsmall"
        className="mb-2"
      />
      <Text left size="normal" className="mt-1">
        {email}
      </Text>
    </div>
  </div>
);

const ThemeView = ({
  theme,
  handleThemeChange,
}: {
  theme: ThemeType;
  handleThemeChange: () => void;
}) => {
  return (
    <div className="second-layer wide-button">
      <Text
        left
        bold="bold"
        color={"dark"}
        opacity="high"
        content="settings.theme.dark_mode"
      />
      <ToggleSwitch onToggle={handleThemeChange} isToggled={theme === "dark"} />
    </div>
  );
};
