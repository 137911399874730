import { h, FunctionComponent, JSX } from "preact";
import Icon, { IconSize } from "../icon";
import Text, { FontWeight, TextColors } from "../text";
import "./index.scss";
// import Loading from "../loading";
import { UnknownObject } from "../../types";

const other = [
  "primary",
  "accent",
  "danger",
  "iconOnly",
  "iconLarge",
  "full",
  "gradient",
  "bottom_float",
  "white",
  "noBorder",
];

const removeUnusedProps = (props: UnknownObject) => {
  const newProps: UnknownObject = {};
  const classess = [].concat(other);
  Object.keys(props).forEach((key: string) => {
    if (classess.indexOf(key) === -1) {
      newProps[key] = props[key];
    }
  });

  return newProps;
};

const addClass = (className = "", options: string[], props: UnknownObject) => {
  options.forEach((option: string) => {
    if (props[option]) {
      className += ` Button--${option}`;
    }
  });
  return className;
};

type ButtonSize = "xs" | "sm" | "md" | "lg" | "xl" | "xl2" | "xl3" | "xl4";

export type CSSProperties = string | { [key: string]: string | number };

interface Props {
  text?: string;
  bold?: FontWeight;
  icon?: string;
  children?: JSX.Element;
  primary?: boolean;
  accent?: boolean;
  className?: string;
  iconOnly?: boolean;
  iconLarge?: boolean;
  // eslint-disable-next-line
  onClick?: (...args: any) => void;
  danger?: boolean;
  full?: boolean;
  loading?: boolean;
  href?: string;
  disabled?: boolean;
  gradient?: boolean;
  bottom_float?: boolean;
  white?: boolean;
  noBorder?: boolean;
  size?: ButtonSize;
  backgroundColor?: "light" | "dark" | "";
  color?: TextColors;
}

const getIconSize = (size: ButtonSize = "md"): IconSize => {
  const map: { [P in ButtonSize]: number } = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
    xl2: 6,
    xl3: 8,
    xl4: 10,
  };

  return map[size] as IconSize;
};

const Button: FunctionComponent<Props> = ({
  text,
  bold,
  icon,
  className = "",
  onClick,
  loading,
  size,
  backgroundColor,
  color,
  ...rest
}) => {
  const onClickFn = onClick;

  const newClassName = [other].reduce(
    (prev, current) => addClass(prev, current, rest as UnknownObject),
    `${className} Button` +
      (size ? ` Button--${size}` : "") +
      (backgroundColor ? ` Button--background__${backgroundColor}` : "")
  );

  const newProps = removeUnusedProps(rest as UnknownObject);
  return (
    <button
      onClick={onClickFn}
      className={newClassName}
      style={{ color }}
      {...newProps}
    >
      {/* TODO: add SVG animation for loading but in horizontal view and small match
      with the buttons*/}

      {loading && text?.length > 0 && (
        <Text content={"button.loading"} bold={bold}></Text>
      )}

      {loading && text?.length < 1 && <Text content={"..."} bold={bold}></Text>}

      {!loading && text && (
        <Text content={text} bold={bold}>
          {text}
        </Text>
      )}
      {!loading && icon && (
        <Icon
          name={icon}
          color={color}
          className="Button__icon"
          size={getIconSize(size)}
        />
      )}
    </button>
  );
};

export default Button;

export const IconButton = (props: Props): JSX.Element => (
  <Button iconOnly {...props} />
);

export const IconColorButton = (
  props: Props & { style?: HTMLStyleElement }
): JSX.Element => (
  <Button iconLarge style={{ color: props.color }} {...props} />
);
