import { h, JSX } from "preact";
import Text from "../../text";

interface Props {
  content: string;
}

const ModalMessage = ({ content }: Props): JSX.Element => {
  return (
    <Text
      content={content}
      className="my-5"
      bold="medium"
      size="small"
      color="light-gray"
    />
  );
};
export default ModalMessage;
