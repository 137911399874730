import { FunctionalComponent, h, JSX } from "preact";
import Toast from "../toast";
import { ThemeType } from "../../types";

interface Props {
  theme: ThemeType;
  children: JSX.Element;
}

const Layout: FunctionalComponent<Props> = ({ theme, children }) => {
  return (
    <span className={`theme-${theme}`}>
      {children}
      <Toast />
    </span>
  );
};

export default Layout;
