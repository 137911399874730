import { h, JSX } from "preact";
import { useEffect, useState } from "preact/hooks";
import { route } from "preact-router";
import { withStore } from "../../store";
import { resetPassword } from "../../utils/http";
import Button from "../../components/button";
import Form from "../../components/form";
import Input from "../../components/input";
import Text from "../../components/text";
import Loading from "../../components/loading";
import Head from "../../components/head";
import { ErrorType, EventTargetType, InputsType } from "../../types";

import "./index.scss";

interface State {
  isSubmitted: boolean;
  loading: boolean;
  isSubmitDisabled: boolean;
  passwordError: string | null;
  newPassword: string;
  confirmNewPassword: string;
  notification: null | string[];
  checkedUrl: boolean;
  urlError: boolean;
  uid: null | string;
  token: null | string;
}

const inputs: InputsType = [
  {
    icon: ["eye", "eye-hide"],
    name: "newPassword",
    type: "password",
    label: "placeholder.new_password",
    position: "top",
    required: true,
    validation: [],
  },
  {
    icon: ["eye", "eye-hide"],
    name: "confirmNewPassword",
    type: "password",
    label: "placeholder.confirm_password",
    position: "bottom",
    required: true,
    validation: [],
  },
];

export default withStore(({ store }) => {
  const [state, setState] = useState<State>({
    isSubmitted: false,
    loading: false,
    isSubmitDisabled: true,
    newPassword: "",
    confirmNewPassword: "",
    passwordError: null,
    notification: null,
    checkedUrl: false,
    urlError: false,
    uid: null,
    token: null,
  });
  const {
    passwordError,
    urlError,
    loading,
    isSubmitDisabled,
    checkedUrl,
    isSubmitted,
    notification,
    uid,
    token,
    newPassword,
    confirmNewPassword,
  } = state;
  const { theme } = store.state;
  const rules = {};

  const handleChangeInput = (e: EventTargetType) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const updateErrorMessage = (error: string[]) => {
    setState({
      ...state,
      notification: error,
    });
  };

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    if (
      !urlError &&
      !loading &&
      newPassword &&
      confirmNewPassword &&
      newPassword === confirmNewPassword
    ) {
      setState({
        ...state,
        loading: true,
      });
      resetPassword({
        token,
        uid,
        new_password1: newPassword,
        new_password2: confirmNewPassword,
      })
        .then(() =>
          setState({
            ...state,
            isSubmitted: true,
            loading: false,
          })
        )
        .catch((err: ErrorType) =>
          setState({
            ...state,
            loading: false,
            notification: err.message.new_password2
              ? err.message.new_password2
              : null,
            urlError: err.message.token,
          })
        );
    }
  };

  useEffect(() => {
    setState({
      ...state,
      passwordError:
        state.confirmNewPassword !== "" &&
        state.confirmNewPassword !== state.newPassword &&
        "error.form.password",
      isSubmitDisabled:
        state.newPassword === "" ||
        state.confirmNewPassword === "" ||
        state.newPassword !== state.confirmNewPassword,
    });
    // eslint-disable-next-line
  }, [state.newPassword, state.confirmNewPassword]);

  useEffect(() => {
    if (!state.checkedUrl) {
      const urlParams = new URLSearchParams(window.location.search);
      const urlToken = urlParams.get("token");
      const urlUid = urlParams.get("uid");
      if (!urlToken || !urlUid) {
        setState({
          ...state,
          checkedUrl: true,
          urlError: true,
        });
      } else {
        setState({
          ...state,
          checkedUrl: true,
          urlError: false,
          uid: urlUid,
          token: urlToken,
        });
      }
    }
  }, [state]);

  return (
    <div className="first-layer reset-password">
      {isSubmitted && (
        <div class="form h-full">
          <div className={"title"}>
            <div className="logo mx-4 mb-8" />
            <Head title="Email is sent" />
            <Text
              content="resetpassword.confirmation.header"
              left
              bold="heavy"
              color="dark"
              size="xtitle"
              spacing="-0.4px"
              className="mb-4"
            />
          </div>
          <Text
            content="resetpassword.confirmation.title"
            left
            color="gray"
            size="normal"
            className="mb-7"
          />
          <div className="button-container">
            <Button
              text="button.login"
              backgroundColor={theme === "dark" && theme}
              gradient
              bottom_float
              onClick={() => route("/login")}
            />
          </div>
        </div>
      )}
      {checkedUrl && !isSubmitted && (
        <Form
          rules={rules}
          className={"h-full pb-2"}
          getErrorMessage={updateErrorMessage}
          onSubmit={handleSubmit}
        >
          {() =>
            !urlError ? (
              <div className="form h-full">
                <div className={"title"}>
                  <div className="logo mx-4 mb-7" />
                  <Text
                    content="resetpassword.title"
                    left
                    bold="heavy"
                    color="dark-gray"
                    size="xtitle"
                    spacing="-0.4px"
                    className="mx-4 mb-4"
                  />
                  {!notification && (
                    <Text
                      content="resetpassword.text"
                      left
                      lineHeight={"28px"}
                      color="gray"
                      size="small"
                      className="mx-4 mb-6"
                    />
                  )}
                </div>
                {notification &&
                  notification.map((error) => (
                    <Text
                      key={error}
                      className="mx-4 mb-3"
                      color="danger"
                      size="small"
                      content={error}
                    />
                  ))}
                <div className="first-layer input-container">
                  {inputs.map(
                    (params): JSX.Element => (
                      <Input
                        key={params.name}
                        {...params}
                        error={passwordError}
                        onInput={handleChangeInput}
                        // eslint-disable-next-line
                        value={state[params.name]}
                      />
                    )
                  )}
                </div>
                <div className="button-container">
                  <Button
                    text={loading ? "button.loading" : "button.save_password"}
                    gradient
                    backgroundColor={theme === "dark" && theme}
                    bottom_float
                    disabled={loading || isSubmitDisabled}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            ) : (
              <div
                className={"reset-password reset-password_error_screen w-1/2"}
              >
                <img
                  className={"mb-5 w-1/1 h-auto text-center"}
                  src="../../components/icon/svg/404Error.svg"
                  alt="Page not found."
                />
                <Text
                  content="resetpassword.wrong_token"
                  center
                  lineHeight={"28px"}
                  color="gray"
                  size="large"
                />
                <div className={"button_wrapper"}>
                  <Button
                    text={"resetpassword.go_login"}
                    gradient
                    bottom_float
                    backgroundColor={theme === "dark" && theme}
                    onClick={() => route("/login")}
                  />
                </div>
              </div>
            )
          }
        </Form>
      )}
      {!checkedUrl && loading && <Loading />}
    </div>
  );
});
