import { FunctionalComponent, h } from "preact";
import PreactHead from "preact-head";

interface Props {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}

const Head: FunctionalComponent<Props> = ({
  title,
  description = "Deploy+",
  image = "",
  url = "",
}) => (
  <PreactHead>
    <meta property="og:title" content={`Deploy+ / ${title}`} />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="article" />
    <meta name="twitter:card" content={image} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    <title>{`Deploy+ / ${title}`}</title>
    <meta name="description" content={description} />
  </PreactHead>
);

export default Head;
