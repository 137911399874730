import { FunctionalComponent, h } from "preact";
import Paper from "../paper";
import Text from "../text";
import "./index.scss";
import { useCallback, useEffect, useState } from "preact/hooks";

//TODO: it is good to add icon to the toast message based on the sketches

type Message = {
  id: string;
  text: {
    title: string;
    content: string;
  };
};

type PostMessage = {
  timeStamp: number;
  data: {
    type: string;
    text: {
      title: string;
      content: string;
    };
  };
};

type State = {
  messages: Message[];
};

const INTERVAL = 2000;
const TYPE = "TOAST";

const Toast: FunctionalComponent = () => {
  const [state, setState] = useState<State>({
    messages: [],
  });

  const handleClose = useCallback(
    (id: string) => {
      setState({
        ...state,
        messages: state.messages.filter((msg) => {
          if (msg.id === id) {
            return false;
          }
          return true;
        }),
      });
    },
    [state]
  );

  const subscribe = useCallback(
    (postMessage: PostMessage) => {
      if (postMessage.data.type === TYPE) {
        const messageId = postMessage.timeStamp.toString();
        const message = {
          id: messageId,
          text: postMessage.data.text,
        };

        setState({
          ...state,
          messages: [...state.messages, message],
        });
      }
    },
    [state]
  );

  useEffect(() => {
    window.addEventListener("message", subscribe);

    const interval = setInterval(() => {
      if (state.messages.length) {
        handleClose(state.messages[0].id);
      } else {
        clearInterval(interval);
      }
    }, INTERVAL);

    return () => {
      window.removeEventListener("message", subscribe);
      clearInterval(interval);
    };
  }, [subscribe, handleClose, state.messages]);

  return (
    <ul className="Toast">
      {state.messages.map((msg) => (
        <Paper
          element="li"
          key={msg.id}
          elevation={2}
          className="Toast__item"
          onClick={() => handleClose(msg.id)}
        >
          <Text size="small" bold="black" className="mb-2">
            {msg.text.title}
          </Text>
          <Text size="small">{msg.text.content}</Text>
        </Paper>
      ))}
    </ul>
  );
};

export const broadcast = (title: string, content: string): void => {
  window.postMessage(
    {
      type: TYPE,
      text: {
        title,
        content,
      },
    },
    "*"
  );
};

export default Toast;
