import { FunctionalComponent, h } from "preact";
import "./index.scss";

type LoadingSizes = "xs" | "sm" | "md";

type LoadingProps = {
  size?: LoadingSizes;
  fullScreen?: boolean;
};

const getSize = (size: LoadingSizes = "md"): number => {
  const map: { [P in LoadingSizes]: number } = {
    xs: 5,
    sm: 10,
    md: 20,
  };

  return map[size];
};

const Loading: FunctionalComponent<LoadingProps> = ({
  size = "md",
  fullScreen,
}) => (
  <div
    className="loading"
    style={{
      height: fullScreen && "100vh",
    }}
  >
    <div className="loader">
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r={getSize(size)}
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  </div>
);

export default Loading;
