import { FunctionalComponent, h } from "preact";
import Text from "../../components/text";
import Input from "../../components/input";
import { InputsType } from "../../types";

interface Props {
  inputs: InputsType;
  notification: string;
  handleChangeInput: (e: KeyboardEvent) => void;
  emailError: string;
  stateInputs: { email: string; username: string; name: string };
}

const ProfileInfoScreen: FunctionalComponent<Props> = ({
  inputs,
  notification,
  handleChangeInput,
  emailError,
  stateInputs,
}) => {
  return (
    <div
      style={{
        padding: "0 var(--project-item-gutter)",
      }}
    >
      <Text
        content="add_user.profile_information"
        left
        bold="bold"
        color="light-gray"
        size="slarge"
        className="mt-5 mb-4"
      />
      <div className="first-layer input-container">
        {inputs.map((params, index) => (
          <Input
            key={params.name}
            {...params}
            error={index === 0 ? notification : index === 1 ? emailError : null}
            onInput={handleChangeInput}
            // eslint-disable-next-line
            value={stateInputs[params.name]}
          />
        ))}
      </div>
    </div>
  );
};

export default ProfileInfoScreen;
