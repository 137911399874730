import { FunctionalComponent, h } from "preact";
import { ContrastColor } from "../../utils/text";
import { formatBuildDateTime } from "../../utils/time";
import { Build as BuildType } from "../../types";
import Text from "../text";

export interface LatestBuildProps extends BuildType {
  short?: boolean;
  textColor?: ContrastColor;
}

const LatestBuild: FunctionalComponent<LatestBuildProps> = ({
  revision_log,
  comment,
  created,
  textColor,
}) => (
  <div>
    {revision_log && (
      <Text className="mb-5" color={textColor} wrap={true}>
        {revision_log}
      </Text>
    )}
    {comment && (
      <Text className="mb-4" color={textColor} wrap={true}>
        {comment}
      </Text>
    )}
    {/* @ts-ignore */}
    <Text.Small color={textColor || "secondary"} opacity={"medium"}>
      {formatBuildDateTime(created, "long")}
    </Text.Small>
  </div>
);

export default LatestBuild;
