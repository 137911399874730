import { Fragment, FunctionalComponent, h, RefObject } from "preact";
import { formatBuildDateTime } from "../../utils/time";
import { IconColorButton } from "../button";
import Text, { TextColors } from "../text";
import { Build as BuildType } from "../../types";
import Icon from "../icon";
import LatestBuild from "../latestbuild";

import "./index.scss";
export interface BuildItemProps extends BuildType {
  handleClick?: (event?: Event) => void;
  handleLinkClick?: (event?: Event) => void;
  hideIcon?: boolean;
  selected?: boolean;
  theme?: TextColors;
  reference?: RefObject<HTMLDivElement> | null;
  featured?: boolean;
}

const BuildItem: FunctionalComponent<BuildItemProps> = (props) => {
  const {
    selected = false,
    created,
    version,
    build_number,
    handleClick,
    handleLinkClick,
    hideIcon = false,
    binary_exists = false,
    theme,
    reference,
    featured,
  } = props;
  const formattedDateTime = formatBuildDateTime(created, "long");
  const darkTheme = theme === "dark" ? "build-item__dark" : "";

  return (
    <div
      ref={reference}
      className={
        "second-layer build-item " +
        (selected ? "build-item__selected " : "") +
        (featured ? "build-item__featured " : "") +
        darkTheme
      }
    >
      <div className="build-item__text flex flex-col justify-around ">
        <Text bold="bold" size={"slarge"}>
          {version} (#{build_number || "Unknown"})
        </Text>
        {selected ? (
          <div className={"mt-3"}>
            <LatestBuild {...props} short={false} textColor={"dark"} />
          </div>
        ) : (
          <Text.Small color={"gray"}>{formattedDateTime}</Text.Small>
        )}
      </div>
      {binary_exists ? (
        !hideIcon && (
          <Fragment>
            {build_number > 0 && (
              <IconColorButton
                icon={theme === "dark" ? "link-small-white" : "link-small"}
                onClick={handleLinkClick}
                size="xl2"
                data-testid="link"
              />
            )}

            <IconColorButton
              icon={
                theme === "dark"
                  ? "download-build-small-white"
                  : "download-build-small"
              }
              onClick={handleClick}
              size="xl2"
              data-testid="build"
            />
          </Fragment>
        )
      ) : (
        <div className="build-item__container">
          <Icon name={"warning-small"} size={4} className="ml-2 mr-3" />
          <Text className="mr-2" size="xxsmall" content="warning.no_download" />
        </div>
      )}
    </div>
  );
};

export default BuildItem;
