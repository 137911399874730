import { h, Fragment, FunctionalComponent } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { useTheme } from "../../utils/hooks";
import {
  ProjectItem,
  ProjectList,
  ProjectSortOrder,
  ProjectType,
} from "../../types";
import { getProjects, searchProjects } from "../../utils/http";
import { queryToObject } from "../../utils/text";
import { compareArrays, getMobileOS, isMobile } from "../../utils/helpers";
import analytics from "../../utils/analytics";
import { FILTER_ITEMS, SORTBY_ITEMS } from "../../utils/constants";
import BuildPlatformFilterMenu from "../../components/BuildPlatformFilterMenu/BuildPlatformFilterMenu";
import DesktopPlatformMenu from "../../components/BuildPlatformFilterMenu/DesktopPlatformMenu";
import DesktopSortingMenu from "../../components/BuildPlatformFilterMenu/DesktopSortingMenu";
import Button from "../../components/button";
import Head from "../../components/head";
import SearchInput from "../../components/searchInput";
import TopBar from "../../components/topNav";
import Loading from "../../components/loading";
import PullToRefresh from "../../components/pulltorefresh";
import Text from "../../components/text";
import Icon from "../../components/icon";

import "./index.scss";
import store from "../../utils/store";

interface StateProps {
  offset: string;
  isLoading: boolean;
  error: boolean;
  loaded: boolean;
  content: ProjectItem[];
  isReload: boolean;
  search: string;
  next: boolean | string;
  nextOffset: string;
  trackingAdded: boolean;
}

interface FilterMenuType {
  showFilterMenu: boolean;
  sortBy: ProjectSortOrder[];
  filter: ProjectType[];
}

const isAndroidDevice = getMobileOS() === "android";
const isIosDevice = getMobileOS() === "ios";
const initialFilter = isAndroidDevice
  ? [FILTER_ITEMS[1].key]
  : isIosDevice
  ? [FILTER_ITEMS[0].key]
  : [FILTER_ITEMS[0].key, FILTER_ITEMS[1].key];

const Home: FunctionalComponent = () => {
  const [theme] = useTheme();
  const [state, setState] = useState<StateProps>({
    offset: "0",
    isLoading: false,
    error: false,
    loaded: false,
    content: [],
    isReload: false,
    search: "",
    next: false,
    nextOffset: null,
    trackingAdded: false,
  });
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const {
    error,
    isLoading,
    offset,
    nextOffset,
    content,
    loaded,
    isReload,
    search,
  } = state;

  const isDesktop = windowWidth > 470 && !isMobile();

  const getStoredFilters = (): ProjectType[] => {
    const storedFilters = store.get("filters");
    return storedFilters
      ? (storedFilters.split(",") as ProjectType[])
      : initialFilter;
  };

  const getStoredSort = () => {
    const storedFilters = store.get("sort");
    return storedFilters
      ? (storedFilters.split(",") as ProjectSortOrder[])
      : [SORTBY_ITEMS[0].key];
  };

  const [filterMenu, setFilterMenu] = useState<FilterMenuType>({
    showFilterMenu: false,
    sortBy: getStoredSort(),
    filter: getStoredFilters(),
  });
  const { filter, showFilterMenu, sortBy } = filterMenu;

  const load = useCallback(
    (
      offSet = "0",
      sortby = SORTBY_ITEMS[0].key,
      reload = false,
      clearSearch = false
    ) => {
      setState({
        ...state,
        isLoading: true,
        isReload: reload,
      });
      void getProjects({
        offset: offSet,
        sortBy: sortby,
        search: clearSearch ? "" : state.search,
      }).then((res) => {
        setState({
          ...state,
          isLoading: false,
          loaded: true,
          isReload: false,
          search: clearSearch ? "" : state.search,
          content: reload ? res.results : [...state.content, ...res.results],
          next: !!res.next,
          nextOffset: res.next
            ? (queryToObject(res.next.split("?")[1]).offset as string)
            : state.nextOffset
            ? state.nextOffset
            : "0.5",
        });
      });
    },
    [state]
  );

  const loadSearch = useCallback(() => {
    if (state.search === "") {
      load("0", filterMenu.sortBy[0], true);
      return;
    }
    setState({
      ...state,
      loaded: false,
      isLoading: true,
    });

    void searchProjects({ search: state.search }).then((res) => {
      setState({
        ...state,
        isLoading: false,
        loaded: true,
        isReload: false,
        content: res.results,
        next: !!res.next,
        nextOffset: res.next
          ? (queryToObject(res.next.split("?")[1]).offset as string)
          : state.nextOffset
          ? state.nextOffset
          : "0.5",
      });
    });
  }, [state, load, filterMenu.sortBy]);

  const handleScroll = useCallback(() => {
    const wrappedElement = document.getElementsByClassName("home-projects");
    if (
      wrappedElement[0].clientHeight - 400 <=
        window.innerHeight + window.scrollY &&
      !state.isLoading &&
      state.nextOffset &&
      state.next
    ) {
      load(state.nextOffset, filterMenu.sortBy[0]);
    }
  }, [filterMenu.sortBy, load, state.isLoading, state.next, state.nextOffset]);

  const handleChangeInput = (event: Event) => {
    const { value } = event.target as HTMLInputElement;
    setState({
      ...state,
      search: value,
    });
  };

  const handleResetInput = () => {
    setState({
      ...state,
      search: "",
    });
    load("0", filterMenu.sortBy[0], true, true);
  };

  const refreshFn = (newSortBy: ProjectSortOrder = filterMenu.sortBy[0]) => {
    setState({
      ...state,
      offset: "0",
      nextOffset: null,
    });

    load("0", newSortBy, true);
  };

  const updateStoredFilters = (filters: string[]) => {
    store.set("filters", filters.join());
  };

  const updateStoredSort = (sortBy: ProjectSortOrder[]) => {
    store.set("sort", sortBy.join());
  };

  const toggleFilterMenu = () => {
    setFilterMenu({
      ...filterMenu,
      showFilterMenu: !filterMenu.showFilterMenu,
    });

    updateStoredFilters(filterMenu.filter);
  };

  const onConfirmFilterMenu = (
    selectedPlatforms: ProjectType[],
    selectedSortBy = filterMenu.sortBy
  ) => {
    if (
      compareArrays(selectedPlatforms, filterMenu.filter) &&
      compareArrays(selectedSortBy, filterMenu.sortBy)
    ) {
      setFilterMenu({
        ...filterMenu,
        showFilterMenu: false,
      });

      updateStoredFilters(selectedPlatforms);
      updateStoredSort(selectedSortBy);
      return;
    }

    setFilterMenu({
      ...filterMenu,
      sortBy: selectedSortBy,
      filter: selectedPlatforms,
      showFilterMenu: false,
    });

    updateStoredFilters(selectedPlatforms);
    updateStoredSort(selectedSortBy);

    refreshFn(selectedSortBy[0]);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (!state.isLoading && !state.loaded) {
      setWindowWidth(window.innerWidth);
      load("0", filterMenu.sortBy[0]);
    }
    if (!state.trackingAdded) {
      setState({
        ...state,
        trackingAdded: true,
      });
      // eslint-disable-next-line
      analytics.page();
    }
  }, [filterMenu.sortBy, load, state]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
    };
  }, [state, handleScroll]);

  let renderContent: ProjectList;
  if (error && !isLoading) {
    renderContent = [];
  } else if (content) {
    if (content && content.length === 0 && loaded) {
      renderContent = [{ componentType: "NoProjects" }];
    } else {
      if (nextOffset && nextOffset !== offset && !isReload) {
        const filteredContent = content.filter((item) =>
          filterMenu.filter.includes(item.type)
        );
        renderContent = filteredContent.length
          ? filteredContent.map((item) => {
              return {
                componentType: "ProjectItem",
                data: { ...item },
              };
            })
          : [{ componentType: "NoProjects" }];
      } else {
        renderContent = [];
      }
    }
  }

  return (
    <div>
      <Head title="Projects" />
      <div className={"first-layer home"}>
        <TopBar />
        <div className="first-layer home-dashboard">
          {isDesktop && (
            <DesktopPlatformMenu
              onChange={onConfirmFilterMenu}
              currentFiltering={filter}
            />
          )}
          <div className="home-projects">
            <SearchInput
              name="builds"
              placeholder="Search Builds"
              value={search}
              onChange={handleChangeInput}
              onReset={handleResetInput}
              onSubmit={loadSearch}
              disabled={isLoading}
            />
            {isDesktop && (
              <DesktopSortingMenu
                theme={theme}
                onChange={onConfirmFilterMenu}
                currentFiltering={filter}
                currentSorting={sortBy}
              />
            )}
            {error ? (
              <Button
                text="button.try_again"
                gradient
                backgroundColor={theme === "dark" && theme}
                onClick={refreshFn}
              />
            ) : (
              <Fragment>
                <div className="home-filter-bar first-layer">
                  <Text bold={"black"} content={"home.header"} />
                  <div
                    className="flex align-center filter-toggle"
                    onClick={toggleFilterMenu}
                  >
                    <Icon
                      className="mr-1"
                      name="filter"
                      size={5}
                      color={theme === "dark" ? "light" : "dark"}
                    />
                    <Text content={"home.modal.title"} />
                  </div>
                </div>
                <PullToRefresh
                  lockScroll={showFilterMenu}
                  theme={theme}
                  content={renderContent}
                  refreshFn={refreshFn}
                />
              </Fragment>
            )}
            {isLoading && <Loading />}
          </div>
          <BuildPlatformFilterMenu
            theme={theme}
            show={filterMenu.showFilterMenu}
            onConfirm={onConfirmFilterMenu}
            selectedSortBy={sortBy}
            selectedPlatforms={filter}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
