import { FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import { withStore } from "../../store";
import Button from "../../components/button";

interface Props {
  default?: boolean;
}

const PageNotFoundPage: FunctionalComponent<Props> = withStore(({ store }) => {
  const { theme } = store.state;

  return (
    <div className={"h-full flex flex-col align-center justify-between"}>
      <div />
      <div className={"text-center w-1/2"}>
        <img
          className={"mb-5 w-1/2 h-auto text-center"}
          src="../../components/icon/svg/404Error.svg"
          alt="Page not found."
        />
        <div>
          <div>
            <div className={"text-normal font-black text-center"}>
              Page not found
            </div>
            <div className={"text-small text-center"}>
              It looks like this page doesn’t exist.
            </div>
          </div>
        </div>
      </div>
      <div className={"px-2 my-7 w-full flex justify-center"}>
        <Button
          text={"Back to Home"}
          gradient
          backgroundColor={theme === "dark" && theme}
          bottom_float
          onClick={() => route("/home")}
        />
      </div>
    </div>
  );
});

export default PageNotFoundPage;
