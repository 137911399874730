import { useState, useEffect } from "preact/hooks";
import store from "./store";
import { ThemeType } from "../types";

export const useTheme: () => [ThemeType, () => void] = () => {
  const [theme, setTheme] = useState<ThemeType>("light");

  const toggleTheme = () => {
    if (theme === "light") {
      store.set("theme", "dark");
      document.body.style.background = "#000000";
      setTheme("dark");
    } else {
      store.set("theme", "light");
      document.body.style.background = "#ffffff";
      setTheme("light");
    }
  };

  useEffect(() => {
    const localTheme: ThemeType = store.getTheme("theme");
    if (localTheme) {
      setTheme(localTheme);
    }
  }, []);

  return [theme, toggleTheme];
};
