import "./style/index.scss";
import "scroll-behavior-polyfill";

import { FunctionalComponent, h } from "preact";
import { Route, Router, route, RouterOnChangeArgs } from "preact-router";
import { useEffect, useState } from "preact/hooks";

import requireAuth from "./containers/requireauth";
import Home from "./containers/home/";
import ProjectDetails from "./containers/projectdetails/";
import Login from "./containers/login/";
import Share from "./containers/share/";
import ForgotCredentials from "./containers/forgotcredentials/";
import ResetPassword from "./containers/resetpassword";
import DownloadBuild from "./containers/downloadbuild";
import ChangePassword from "./containers/changepassword/";
import { IntlProvider } from "preact-i18n";
import Layout from "./components/layout";
import { Store, getInitialStoreState, StoreState } from "./store";
import { useTheme } from "./utils/hooks";

import {
  AppReadyEvent,
  LDAPUserWithNoEmailFoundEvent,
} from "./utils/app-events";

import definition from "./i18n/en.json";
import localStore from "./utils/store";
import Dialog from "./components/modal/dialog";
import ModalMessage from "./components/modal/message";
import { InternalServerErrorPage } from "./containers/InternalServerError/InternalServerError";
import PageNotFoundPage from "./containers/page-not-found/PageNotFound";
import { OfflineErrorPage } from "./containers/offlineError";
import { ServerUnreachableError } from "./containers/serverUnreachableError";
import Settings from "./containers/settings";
import AddUserScreen from "./containers/addUser";

//TODO: Polyfill for IntersectionObserver, remove this entry and uninstall the package once we stop supporting iOS 12.1
require("intersection-observer");

if (module.hot) {
  require("preact/debug");
}

const App: FunctionalComponent = () => {
  const handleRoute = (e: RouterOnChangeArgs) => {
    localStore.set("router:previous", e.previous || "");
  };

  const updateStore = (newState: StoreState): StoreState => {
    setTimeout(() => {
      setStore((curState) => ({
        ...curState,
        state: { ...curState.state, ...newState },
      }));
    });
    return newState;
  };

  const [store, setStore] = useState(getInitialStoreState(updateStore));
  const [theme, toggleTheme] = useTheme();

  const onConfirmEmailAlter = () => {
    localStore.set("ldap_user_with_no_email_found_warning_shown", "true");
    updateStore({
      ...store.state,
      showLDAPUserWithNoEmailWarning: false,
    });
  };

  const updateOnlineStatus = () => {
    if (!navigator.onLine) {
      route("/offline", false);
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    if (!theme) {
      toggleTheme();
    }

    LDAPUserWithNoEmailFoundEvent.on(() => {
      updateStore({
        ...store.state,
        showLDAPUserWithNoEmailWarning: true,
      });
    });

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    AppReadyEvent.emit();

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, [store.state, theme, toggleTheme]);

  return (
    <IntlProvider definition={definition}>
      <Store.Provider value={store}>
        <Layout theme={store.state.theme}>
          <div className="app-wrapper">
            <Router onChange={handleRoute}>
              <Route path="/" component={Login} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-credentials" component={ForgotCredentials} />
              <Route path="/home" component={requireAuth(Home)} />
              <Route
                path="/project/:id/:hash"
                component={requireAuth(ProjectDetails)}
              />
              <Route
                path="/project/:id"
                component={requireAuth(ProjectDetails)}
              />
              <Route
                path="/builds/:uuid/download"
                component={requireAuth(DownloadBuild)}
              />
              <Route path="/share/:type/:id" component={requireAuth(Share)} />
              <Route path="/settings" component={requireAuth(Settings)} />
              <Route path="/add-user" component={requireAuth(AddUserScreen)} />
              <Route
                path="/change-password"
                component={requireAuth(ChangePassword)}
              />
              <Route path="/password/reset" component={ResetPassword} />
              <Route path="/error" component={InternalServerErrorPage} />
              <Route path="/offline" component={OfflineErrorPage} />
              <Route path="/unreachable" component={ServerUnreachableError} />
              <PageNotFoundPage default />
            </Router>
          </div>
        </Layout>
        {store.state.showLDAPUserWithNoEmailWarning && (
          <Dialog
            theme={store.state.theme}
            title="warning.LDAPUserWithNoEmailWarning.title"
            onConfirm={onConfirmEmailAlter}
          >
            <ModalMessage content={"warning.LDAPUserWithNoEmailWarning.body"} />
          </Dialog>
        )}
      </Store.Provider>
    </IntlProvider>
  );
};

export default App;

if (process.env.NODE_ENV === "production") {
  if (typeof window !== "undefined" && "serviceWorker" in window.navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker.register("/service-worker.js").then(
        (registration) => {
          // Registration was successful
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          );
        },
        (err) => {
          // registration failed :(
          console.log("ServiceWorker registration failed: ", err);
        }
      );
    });
  }
}
