import { ThemeType } from "../types";

const set = (key: string, value: string): void => {
  typeof window !== "undefined" && localStorage.setItem(key, value);
};

const get = (key: string): string =>
  typeof window !== "undefined" && localStorage.getItem(key);

const getTheme = (key: string): ThemeType => {
  if (typeof window !== "undefined") {
    return <ThemeType>localStorage.getItem(key);
  }
};

const remove = (key: string): void =>
  typeof window !== "undefined" && localStorage.removeItem(key);

const clear = (): void => {
  if (typeof window !== "undefined") {
    const redirect = localStorage.getItem("redirect");
    const theme = localStorage.getItem("theme");
    localStorage.clear();
    redirect && localStorage.setItem("redirect", redirect);
    theme && localStorage.setItem("theme", theme);
  }
};

export default {
  get,
  getTheme,
  set,
  remove,
  clear,
};
