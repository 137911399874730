import { FunctionalComponent, h } from "preact";

import "./index.scss";

interface Props {
  isToggled: boolean;
  onToggle: (e?: Event) => void;
}

const ToggleSwitch: FunctionalComponent<Props> = ({ isToggled, onToggle }) => {
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className="switch" />
    </label>
  );
};

export default ToggleSwitch;
