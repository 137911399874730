import { FunctionalComponent, h } from "preact";
import Text from "../text";
import Icon from "../icon";
import { ProjectFilter, ProjectType } from "../../types";
import analytics from "../../utils/analytics";

import "./index.scss";

const filterOptions: ProjectFilter = [
  {
    icon: "android",
    title: "android",
  },
  {
    icon: "apple",
    title: "ios",
  },
  {
    icon: "android",
    title: "universal",
  },
];

interface FilterOptionType {
  icon: "android" | "apple";
  title: ProjectType;
  selected: ProjectType[];
  onChange: (args: ProjectType[]) => void;
}

const FilterOption: FunctionalComponent<FilterOptionType> = ({
  icon,
  title,
  selected,
  onChange,
}) => {
  const isSelected: boolean = selected.includes(title);

  return (
    <div
      className={`desktop-platform-menu-item
        ${isSelected ? "desktop-platform-menu-item--selected" : ""}`}
      onClick={() => onChange([title])}
    >
      <Icon size={2} name={icon} />
      <Text content={title} bold="bold" size="normal" />
    </div>
  );
};

interface DesktopPlatformType {
  onChange: (args: ProjectType[]) => void;
  currentFiltering: ProjectType[];
}

const DesktopPlatformMenu: FunctionalComponent<DesktopPlatformType> = ({
  onChange,
  currentFiltering,
}) => {
  const handleFilterChange = (option: ProjectType[]) => {
    if (currentFiltering.length === 1 && currentFiltering.includes(option[0])) {
      return;
    } else if (currentFiltering.includes(option[0])) {
      // eslint-disable-next-line
      analytics.track("changePlatform", {
        newPlatforms: option,
      });
      return onChange(
        currentFiltering.filter((filter) => filter !== option[0])
      );
    }

    return onChange([...currentFiltering, option[0]]);
  };

  return (
    <div className="second-layer desktop-platform-menu">
      <Text content="Filters" bold="bold" className="mb-2" size="slarge" />
      {filterOptions.map((option) => (
        <FilterOption
          key={option.title}
          icon={option.icon}
          title={option.title}
          selected={currentFiltering}
          onChange={handleFilterChange}
        />
      ))}
    </div>
  );
};

export default DesktopPlatformMenu;
