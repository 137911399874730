import { FunctionalComponent, h } from "preact";
import { route } from "preact-router";
import { useState } from "preact/hooks";

import { ForgotResponse } from "../../types/responses";
import { goBack } from "../../utils/helpers";
import { forgotPassword } from "../../utils/http";
import { withStore } from "../../store";
import { isEmail } from "../../components/form/validators";
import Button, { IconButton } from "../../components/button";
import Content from "../../components/content";
import Form from "../../components/form";
import Head from "../../components/head";
import Input from "../../components/input";
import Dialog from "../../components/modal/dialog";
import ModalMessage from "../../components/modal/message";
import Text from "../../components/text";
import { EventTargetType, InputsType } from "../../types";

import "./index.scss";

interface State {
  isSubmitted: boolean;
  modalOpen: boolean;
  loading: boolean;
  isSubmitDisabled: boolean;
  errors: { [key: string]: string | null };
  email: string;
  notification: string;
}

const ForgotCredentials: FunctionalComponent = withStore(({ store }) => {
  const [state, setState] = useState<State>({
    isSubmitted: false,
    modalOpen: false,
    loading: false,
    isSubmitDisabled: true,
    email: "",
    errors: {},
    notification: "",
  });
  const {
    notification,
    loading,
    modalOpen,
    isSubmitted,
    isSubmitDisabled,
  } = state;
  const { theme } = store.state;
  const isDarkTheme: boolean = theme === "dark";

  const inputs: InputsType = [
    {
      name: "email",
      type: "email",
      label: "placeholder.email",
      position: "top-bottom",
      validation: [],
    },
  ];

  const handleModalButton = () => {
    setState({
      ...state,
      modalOpen: false,
    });
  };

  const handleChangeInput = (e: EventTargetType) => {
    setState({
      ...state,
      email: e.target.value,
      notification: !isEmail(e.target.value) && "error.form.email",
      isSubmitDisabled: !isEmail(e.target.value),
    });
  };

  const handleSubmit = (event: Event) => {
    event.preventDefault();
    const { email } = state;

    if (!state.notification) {
      setState({
        ...state,
        loading: true,
      });
      forgotPassword({ email })
        .then((data: ForgotResponse) => {
          setState({
            ...state,
            loading: false,
            notification: data.detail,
            isSubmitted: true,
          });
        })
        .catch((error: { message: string }) => {
          switch (error.message) {
            case "not_local_user":
              setState({
                ...state,
                loading: false,
                modalOpen: true,
              });
              break;
            case "unknown_email":
              setState({
                ...state,
                loading: false,
                notification: "error.unknown_email",
              });
              break;
            default:
              setState({
                ...state,
                loading: false,
                notification: error.message,
              });
          }
        });
    }
  };

  return (
    <div className="first-layer forgot-credentials">
      <Content firstLayer noHeader padded fullHeight>
        {modalOpen && (
          <Dialog
            theme={theme}
            className={"second_layer"}
            title="modal.title"
            onConfirm={handleModalButton}
            onClose={handleModalButton}
          >
            <ModalMessage content="modal.content" />
          </Dialog>
        )}
        {isSubmitted ? (
          <div class="form h-full">
            <div className={"title"}>
              <div className="logo mx-4 mb-8 mt-6" />
              <Head title="Email is sent" />
              <Text
                content="forgotcredentials.confirmation.header"
                left
                bold="heavy"
                color="dark"
                size="sheading"
                spacing="-0.4px"
                className="mb-4"
              />
            </div>
            <Text
              content="forgotcredentials.confirmation.title"
              left
              color="gray"
              size="normal"
              className="mb-7"
            />
            <div className="button-container">
              <Button
                text="button.login"
                backgroundColor={theme === "dark" && theme}
                gradient
                bottom_float
                onClick={() => route("/login")}
              />
            </div>
          </div>
        ) : (
          <Form rules={{}} onSubmit={handleSubmit} className={"h-full pb-2"}>
            {() => (
              <div className="form h-full">
                <div>
                  <IconButton
                    icon="icon_arrow_back"
                    className="ml-2"
                    color={isDarkTheme ? "light" : "dark"}
                    onClick={goBack}
                    noBorder
                  />
                </div>
                <div className={"title"}>
                  <div className="logo mx-4 mb-8 mt-6" />
                  <Head title="Forgot password" />
                  <Text
                    content="forgotcredentials.main.header"
                    left
                    bold="heavy"
                    color="dark-gray"
                    size="xtitle"
                    spacing="-0.4px"
                    className="mx-4 mb-4"
                  />
                </div>
                <Text
                  content="forgotcredentials.main.title"
                  left
                  color="gray"
                  size="small"
                  className="mx-4 mb-6"
                />
                <div className="first-layer input-container">
                  {inputs.map((params) => (
                    <Input
                      key={params.name}
                      {...params}
                      error={notification}
                      onInput={handleChangeInput}
                      // eslint-disable-next-line
                      value={state[params.name]}
                    />
                  ))}
                </div>
                <div className="button-container">
                  <Button
                    text={loading ? "button.loading" : "button.send"}
                    gradient
                    onClick={handleSubmit}
                    backgroundColor={isDarkTheme && theme}
                    bottom_float
                    disabled={loading || isSubmitDisabled}
                  />
                </div>
              </div>
            )}
          </Form>
        )}
      </Content>
    </div>
  );
});

export default ForgotCredentials;
