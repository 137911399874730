import { h, JSX } from "preact";
import Icon from "../icon";
import Text from "../text";
import "./index.scss";

interface Props {
  icon?: string;
  title?: string;
  content?: string;
}

const MessageView = ({ icon, title, content }: Props): JSX.Element => (
  <div className="no-projects">
    {icon && <Icon name={icon} size={8} className="mt-15" />}
    {title && (
      <Text
        content={title}
        center
        bold="black"
        color="light-gray"
        size="normal"
        className="mt-4"
      />
    )}
    {content && (
      <Text
        content={content}
        center
        bold="medium"
        color="light-gray"
        size="small"
        className="mt-3"
      />
    )}
  </div>
);

export default MessageView;
