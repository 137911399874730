export const isEmail = (email: string): boolean => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const min = (size: number) => (value: string): boolean =>
  value.toString().length >= size;

export const max = (size: number) => (value: string): boolean =>
  value.toString().length <= size;

type ValueType = string | number | null;

export const hasValue = (value: ValueType): ValueType => value;

export const sameAs = (first: string) => (second: string): boolean =>
  first === second;

export const emailRule = {
  errorMessage: "error.form.email",
  rule: isEmail,
};

export const requiredRule = {
  errorMessage: "error.form.required",
  rule: hasValue,
};

export const passwordRule = (
  password: string | null
): Record<string, unknown> => ({
  errorMessage: "error.form.password",
  rule: sameAs(password),
});
