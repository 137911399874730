import { FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { route } from "preact-router";

import analytics from "../../utils/analytics";
import { LoginResponse } from "../../types/responses";
import { UserChangedEvent } from "../../utils/app-events";
import { login } from "../../utils/http";
import localStore from "../../utils/store";
import { withStore } from "../../store";
import Button from "../../components/button";
import Content from "../../components/content";
import Form from "../../components/form";
import Input from "../../components/input";
import IsAuthenticated from "../../components/isauthenticated";
import Text from "../../components/text";

import "./index.scss";

interface State {
  analyticsSet: boolean;
  loading: boolean;
  isSubmitDisabled: boolean;
  notification: string | null;
  inputs: { [key: string]: string };
}

const Login: FunctionalComponent = withStore(({ store }) => {
  const [state, setState] = useState<State>({
    analyticsSet: false,
    loading: false,
    isSubmitDisabled: true,
    notification: null,
    inputs: {
      username: "",
      password: "",
    },
  });

  const updateErrorMessage = (notification: string) => {
    setState({
      ...state,
      notification,
    });
  };

  const handleChangeInput = (e: KeyboardEvent) => {
    const target = e.target as HTMLTextAreaElement;

    setState({
      ...state,
      inputs: {
        ...state.inputs,
        [target.name]: target.value,
      },
    });
  };

  const handleSubmit = (event: Event) => {
    event.preventDefault();
    const { username, password } = state.inputs;
    setState({
      ...state,
      loading: true,
      notification: null,
    });

    login({ username, password })
      .then(({ key, refresh }: LoginResponse) => {
        localStore.set("token", key);
        localStore.set("refreshToken", refresh);
        // eslint-disable-next-line
        analytics.reset().then(() => analytics.identify(key));
        setState({
          ...state,
          loading: false,
        });

        const redirect: string = localStore.get("redirect") || "home";
        route(redirect);

        UserChangedEvent.emit();

        localStore.remove("redirect");
      })
      .catch(({ message: error }: { message: string }) => {
        setState({
          ...state,
          loading: false,
          notification: error,
        });
      });
  };

  useEffect(() => {
    if (!state.analyticsSet) {
      // eslint-disable-next-line
      analytics.page();
      setState({
        ...state,
        analyticsSet: true,
      });
    }
  }, [state]);

  const { loading, notification } = state;
  const btnLabel = !loading ? "button.login" : "button.loading";
  const { theme } = store.state;

  const inputs = [
    {
      name: "username",
      type: "text",
      label: "placeholder.user_name",
      position: "top",
      required: true,
      validation: [],
    },
    {
      icon: ["eye", "eye-hide"],
      name: "password",
      type: "password",
      iconColor: theme === "dark" ? "light" : "dark",
      label: "placeholder.password",
      position: "bottom",
      required: true,
      validation: [],
    },
  ];

  const rules = {};

  return (
    <IsAuthenticated
      yes={() => {
        route("/home");
        return null;
      }}
      no={() => (
        <Form
          rules={rules}
          onSubmit={handleSubmit}
          className={"first-layer login-form h-full"}
          getErrorMessage={updateErrorMessage}
        >
          {() => (
            <Content padded noHeader fullHeight>
              <div className="first-layer form h-full">
                <div className={"title"}>
                  <div className="logo ml-4 mb-7 mt-6" />
                  <Text
                    content="login.header"
                    left
                    bold="heavy"
                    color="dark-gray"
                    size="xtitle"
                    spacing="-0.4px"
                    className="ml-4 mb-2"
                  />
                </div>
                <Text
                  content="login.title"
                  left
                  color="gray"
                  size="normal"
                  className="ml-4 mb-6"
                />
                <div className="first-layer input-container">
                  {inputs.map((params, index) => (
                    <Input
                      key={params.name}
                      {...params}
                      error={index === 0 && notification}
                      onInput={handleChangeInput}
                      value={state.inputs[params.name]}
                    />
                  ))}
                </div>
                <Text
                  content="link.forgot_credentials"
                  style={{ "margin-bottom": "15vh" }}
                  size="normal"
                  bold="semibold"
                  left
                  spacing="0.1px"
                  className="mt-4 ml-4"
                />
                <div className="button-container">
                  <Button
                    text={btnLabel}
                    gradient
                    backgroundColor={theme === "dark" && theme}
                    bottom_float
                    onClick={handleSubmit}
                    disabled={
                      loading ||
                      state.inputs.username === "" ||
                      state.inputs.password === ""
                    }
                  />
                </div>
              </div>
            </Content>
          )}
        </Form>
      )}
    />
  );
});

export default Login;
