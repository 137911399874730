import classNames from "classnames";
import { FunctionalComponent, h, JSX } from "preact";
import { TextColors } from "../text";
import "./index.scss";
import { useEffect, useState } from "preact/hooks";

export type IconSize = 1 | 2 | 3 | 4 | 4.5 | 5 | 6 | 7 | 8;

interface Props {
  name: string;
  className?: string;
  onClick?: (event?: Event) => void;
  size?: IconSize;
  alt?: string;
  color?: TextColors;
}

interface State {
  icon: JSX.Element | null;
}

type Icon_T = { default: JSX.Element };

const getIconSize = (size: IconSize = 2) => {
  const map: { [P in IconSize]: string } = {
    1: "16px",
    2: "18px",
    3: "20px",
    4: "22px",
    4.5: "24px",
    5: "28px",
    6: "40px",
    7: "6em",
    8: "13em",
  };

  return map[size];
};

const Icon: FunctionalComponent<Props> = ({
  className,
  name,
  alt,
  size,
  color,
  ...rest
}) => {
  const [AsyncIcon, setAsyncIcon] = useState<State>(null);

  useEffect((): void => {
    // eslint-disable-next-line
    import(`./svg/${name}.svg`).then((icon: Icon_T) => {
      setAsyncIcon({
        icon: icon.default,
      });
    });
  }, [name]);

  if (AsyncIcon) {
    const classes = classNames(
      className,
      { "fill-current": color },
      { [`text-${color}`]: color }
    );
    const iconSize = getIconSize(size);
    const style = {
      width: iconSize,
      height: iconSize,
    };

    return (
      <span style={style} className={classes} {...rest}>
        {/* @ts-ignore */}
        <AsyncIcon.icon style={style} />
        {alt && <span className="sr-only">{alt}</span>}
      </span>
    );
  }

  return null;
};

export default Icon;
